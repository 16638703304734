/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #929292;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 11px;
  }

  *::-webkit-scrollbar-track {
    background: #eee;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #929292;
    border-radius: 0px;
    border: 0px none #eee;
  }