@use './themes/defaultTheme';
@use './util';
@use './base';
@use './layout/app';
@use './components/list';
@import './partials/navbar';
@import './partials/footer';
@import './partials/scrollbar';
@import './partials/admin';
@import './partials/home';
@import './partials/contact';
@import './partials/projects';
@import './partials/timeline';
@import './util/fonts';

$primary-color: #5a023f;
$secondary-color: #d37e9f;

img {
  overflow: hidden;
}

body {
  background-color: #26262b;

  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}

nav,
footer {
  background-color: $primary-color;
}

nav {
  border-bottom: 1.8rem $secondary-color solid;
}

footer {
  border-top: 0.5rem $secondary-color solid;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
