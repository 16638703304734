#root {
  overflow-x: hidden;
}
.timeline-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 250px;
  @media (max-width: 1000px) {
    margin-top: 3rem;
    min-height: 100vh;
  }
  @media (min-width: 1000px) {
    height: 67vh;
  }
  overflow-x: hidden;
  width: 100vw;
  .timeline {
    // width: 100vw;
    height: 50vh;
    transition: 0.65s ease-in-out;
    background-position: top left;
    background-size: 200vw 50%;
    background-repeat: repeat-x;
    position: absolute;
    display: flex;
    @media (max-width: 1000px) {
      display: none;
    }
    // max-width: 100vw;
    .timeline-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      animation: fade-in 0.7s ease-in-out;
      filter: none; // Resetting filter for .timeline-item
      gap: 0.25rem;
      .outer-circle {
        width: 1.25vw;
        height: 1.25vw;
        border-radius: 50%;
        border: 0.1vw solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        .inner-circle {
          background-color: white;
          height: 0.7vw;
          width: 0.7vw;
          border-radius: 50%;
        }
      }
      h3 {
        font-size: 34px;
        font-weight: normal;
        // font-family: noticia-italic-bold;
        color: white;
      }
      .project-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-size: cover;
        background-position: center;
        min-width: 18vw;
        aspect-ratio: 3/1;
        padding-left: 1vw;
        padding-right: 1vw;
        border-radius: 20rem;
        h2 {
          color: white;
          // font-family: noticia-bold;
          font-weight: normal;
          font-size: 20px;
          margin-bottom: 0;
        }
        button {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    #timeline-1 {
      transform: translateY(-3rem);
    }
    #timeline-2 {
      flex-direction: column-reverse;
      transform: translateY(-14rem);
    }
    #timeline-3 {
      transform: translateY(-1rem);
    }
    #timeline-4 {
      transform: translateY(6rem);
    }
    #timeline-5 {
      transform: translateY(3rem);
    }
    #timeline-6 {
      flex-direction: column-reverse;
      transform: translateY(-9rem);
    }
  }
  .mobile-large-timeline {
    @media only screen and (min-width: 1000px) {
      display: none;
    }
    @media only screen and (max-width: 500px) {
      display: none;
    }
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: clamp(0rem, -0.521rem + 1.667vw, 0.313rem);
    // height: auto;
    // min-height: 100vh;
    overflow-y: visible;
    margin-bottom: 3rem;
    .line {
      position: absolute;
      width: 5px;
      min-height: 100vh;
      height: calc(100% + 10vh);
      background-color: #ff3c6b;
      z-index: -1;
      box-shadow: 0px 0px 50px 4px #ff3c6b;
      @media only screen and (max-height: 900px) {
        height: calc(100% + 10rem);
      }
      @media only screen and (max-height: 800px) {
        height: calc(100% + 20rem);
      }
      @media only screen and (max-height: 700px) {
        height: calc(100% + 30rem);
      }
      @media only screen and (max-height: 600px) {
        height: calc(100% + 40rem);
      }
    }
    .timeline-item {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      .dashed-line {
        height: 5rem;
      }
      .date-project-container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin-top: 35%;
        h3 {
          color: white;
          // font-family: noticia-italic-bold;
          margin-bottom: 0;
          font-size: 1.75rem;
        }
        .project-card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: clamp(11rem, 1rem + 32vw, 16rem);
          aspect-ratio: 5/1;
          padding-left: 1rem;
          padding-right: 1rem;
          border-radius: 220rem;
          h2 {
            color: white;
            // font-family: noticia-bold;
            font-weight: normal;
            font-size: clamp(0.938rem, 0.313rem + 2vw, 1.25rem);
            margin-bottom: 0;
          }
          button {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    #timeline-0 {
      transform: translateX(calc(50% + 2.5rem));
      .dashed-line {
        transform: rotate(90deg) translateY(40%);
      }
      .date-project-container {
        align-items: flex-start;
        margin-left: 1rem;
      }
    }
    #timeline-1 {
      transform: translateX(calc(-50% - 2.5rem));
      flex-direction: row-reverse;
      .dashed-line {
        transform: rotate(90deg) translateY(-40%);
      }
      .date-project-container {
        align-items: flex-end;
        margin-right: 1rem;
      }
    }
  }
  .mobile-small-timeline {
    @media only screen and (min-width: 500px) {
      display: none;
    }
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: clamp(0rem, -0.521rem + 1.667vw, 0.313rem);
    // height: auto;
    // min-height: 100vh;
    overflow-y: visible;
    margin-bottom: 3rem;
    .line {
      position: fixed;
      width: 5px;
      min-height: 100vh;
      height: 100vh;
      top: 0;
      margin-left: clamp(2rem, -2rem + 21.333vw, 4rem);
      background-color: #ff3c6b;
      z-index: -1;
      box-shadow: 0px 0px 50px 4px #ff3c6b;
      @media only screen and (max-height: 900px) {
        height: calc(100% + 10rem);
      }
      @media only screen and (max-height: 800px) {
        height: calc(100% + 20rem);
      }
      @media only screen and (max-height: 700px) {
        height: calc(100% + 30rem);
      }
      @media only screen and (max-height: 600px) {
        height: calc(100% + 40rem);
      }
    }
    .timeline-item {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      transform: translateX(clamp(4rem, -2rem + 32vw, 7rem));
      .dashed-line {
        height: 4rem;
        transform: rotate(90deg) translateY(40%);
      }
      .date-project-container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin-top: 35%;
        align-items: flex-start;
        margin-left: 1rem;
        h3 {
          color: white;
          // font-family: noticia-italic-bold;
          margin-bottom: 0;
          font-size: clamp(1.5rem, 1rem + 2.667vw, 1.75rem);
        }
        .project-card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: clamp(11rem, -1rem + 64vw, 16rem);
          aspect-ratio: 4/1;
          padding-left: 1rem;
          padding-right: 1rem;
          border-radius: 220rem;
          h2 {
            color: white;
            // font-family: noticia-bold;
            font-weight: normal;
            font-size: clamp(0.938rem, 0.313rem + 2vw, 1.25rem);
            margin-bottom: 0;
          }
          button {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  .button-container {
    // position: fixed;
    @media (max-width: 1000px) {
      display: none;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    top: 90vh;
    width: 20vw;
    left: calc(40vw);
    position: absolute;
    gap: 1rem;
    button {
      color: white;
      filter: invert(1);
      height: 3rem;
      img {
        height: 100%;
      }
    }
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
