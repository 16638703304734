@use '../themes/defaultTheme' as *;
@use '../util/variables' as *;
@use '../util/mixins' as *;

body {
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  //   Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: $primary-5;
  color: $primary-3;
  line-height: 1.5;
  font-size: 0.875rem;
}

main {
  min-height: 100vh;
  @include flex-center;
}

.main-loading {
  min-height: calc(100vh - 120.8px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.container {
  width: 90vw;
  margin: 5rem 0;
  max-width: $fixed-width;
  background: $white;
  border-radius: $radius;
  padding: 1.5rem 2rem;
  box-shadow: $dark-shadow;
  h3 {
    font-weight: normal;
    text-transform: none;
    margin-bottom: 2rem;
  }
  button {
    color: $white;
    @include flex-center;
    flex-direction: row;
    width: 45%;
    border-color: transparent;
    background: $primary-4;
    margin: 2rem auto 0 auto;
    text-transform: capitalize;
    font-size: 1.2rem;
    padding: 0.5rem 0;
    letter-spacing: $spacing;
    border-radius: $radius;
    outline: none;
  }
  .buttons {
    @include flex-center;
  }
}
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: $max-width;
}

@include large {
  .section {
    width: 95vw;
  }
}
