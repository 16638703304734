//dark shades of primary color
$primary-0: hsl(200, 70%, 21%);
$primary-1: hsl(200, 70%, 29%);
$primary-2: hsl(200, 70%, 37%);
$primary-3: hsl(200, 70%, 45%);
$primary-4: hsl(200, 70%, 52%); //The primary color
$primary-5: hsl(200, 70%, 60%);
$primary-6: hsl(200, 70%, 67%);
$primary-7: hsl(200, 70%, 74%);
$primary-8: hsl(200, 70%, 81%);
$primary-9: hsl(200, 70%, 88%);
//lighter shades of primary color
//dark shades of seconday color
$seconday-0: hsl(35, 70%, 21%);
$seconday-1: hsl(35, 70%, 29%);
$seconday-2: hsl(35, 70%, 37%);
$seconday-3: hsl(35, 70%, 45%);
$seconday-4: hsl(35, 70%, 52%); //The seconday color
$seconday-5: hsl(35, 70%, 60%);
$seconday-6: hsl(35, 70%, 67%);
$seconday-7: hsl(35, 70%, 74%);
$seconday-8: hsl(35, 70%, 81%);
$seconday-9: hsl(35, 70%, 88%);
//lighter shades of seconday color
//dark shades of grey color
$grey-0: hsl(210, 30%, 21%);
$grey-1: hsl(210, 30%, 29%);
$grey-2: hsl(210, 30%, 37%);
$grey-3: hsl(210, 30%, 45%);
$grey-4: hsl(210, 30%, 52%); //The grey color
$grey-5: hsl(210, 30%, 60%);
$grey-6: hsl(210, 30%, 67%);
$grey-7: hsl(210, 30%, 74%);
$grey-8: hsl(210, 30%, 81%);
$grey-9: hsl(210, 30%, 88%);
//lighter shades of grey color

//misc colors
$white: #eee;
$black: #222;
$red-dark: hsl(0, 100%, 40%);
$red-light: hsl(360, 67%, 67%);
$green-dark: hsl(125, 75%, 40%);
$green-light: hsl(125, 67%, 75%);
