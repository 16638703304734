.admin-login-form-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .admin-login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.1vw white solid;
    padding-top: 10vh;
    padding-bottom: 10vh;
    border-radius: 2vw;
    h1 {
      font-size: 3vw;
    }
    button {
      border: 0.2vw white solid;
      width: 20vw;
      margin: 1vw;
      font-size: 1vw;
      color: white;
      transform: scale(1);
      transition: 0.5s;
      aspect-ratio: 16/2;
      border-radius: 6vw;
    }
    input {
      border: 0.2vw white solid;
      border-radius: 6vw;
      margin: 1vw;
      // padding: 2vw;
      padding-left: 1.25vw;
      width: 30vw;
      aspect-ratio: 16/2;
      font-size: 1vw;
      color: white;
      transform: scale(1);
      transition: 0.5s;

      &:focus {
        border: 0.2vw rgb(96, 215, 255) solid;
        transform: scale(1.01);
        outline: none;
      }
    }
  }
  .qr-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.1vw white solid;
    padding-top: 10vh;
    padding-bottom: 10vh;
    border-radius: 2vw;
    img {
      border-radius: 25px;
    }
    input {
      border: 0.2vw white solid;
      border-radius: 6vw;
      margin: 1vw;
      // padding: 2vw;
      padding-left: 1.25vw;
      width: 30vw;
      aspect-ratio: 16/2;
      font-size: 1vw;
      color: white;
      transform: scale(1);
      transition: 0.5s;

      &:focus {
        border: 0.2vw rgb(96, 215, 255) solid;
        transform: scale(1.01);
        outline: none;
      }
    }
    button {
      border: 0.2vw white solid;
      width: 20vw;
      margin: 1vw;
      font-size: 1vw;
      color: white;
      transform: scale(1);
      transition: 0.5s;
      aspect-ratio: 16/2;
      border-radius: 6vw;
    }
  }
}
.plus-button {
  height: 2rem;
  aspect-ratio: 1/1;
  position: absolute;
  filter: invert(1);
  margin-right: 7rem;
  margin-top: 2rem;
}
.minus-button {
  height: 2rem;
  aspect-ratio: 1/1;
  position: absolute;
  filter: invert(1);
  margin-top: 15rem;
}
.nav-plus {
  aspect-ratio: 1/1;
  filter: invert(1);
  position: absolute;
  top: 30px;
  left: 20px;
}

.color-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  transform: translate(-30rem, -10rem);
  height: 100px;
  .rgba-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rgba-fields {
      width: auto;
      height: 100px;
      justify-content: space-evenly;
      div {
        display: flex;
      }
    }
  }
}
.vertical-color {
  flex-direction: column;
  transform: translate(-10rem, -5rem);
}
textarea {
  font-size: inherit;
  font-family: inherit;
  resize: none;
  height: auto;
}

.project-form {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  border: 1px solid white;
  padding: 2rem;
  border-radius: 2rem;
  input {
    color: white;
  }
  div {
    input {
      width: 100%;
    }

    p {
      margin-bottom: 0;
    }
  }

  #date {
    color-scheme: dark;
  }
  button {
    background-color: rgb(0, 121, 0);
    width: 10rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 500px;
    margin: 0 auto;
    color: white;
  }
}
.admin-project-container {
  display: flex;
  flex-direction: column;
}
.gradient-container {
  .color-picker {
    transform: translate(2rem, -5rem);
    z-index: 1000;
  }
}
