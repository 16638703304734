.project-container-main {
  width: 100vw;
  padding: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 4.5vw;
    color: #eeeeee;
  }
}

.project-search {
  border: 2px #7c7c7c solid;
  border-radius: 2vw;

  outline: none;

  color: #7c7c7c;

  padding: 1vw;
  margin-top: 1.5vw;
  width: 50vw;

  font-size: 1.75vw;

  transform: scale(1);
  transition: 0.2s;

  &:focus {
    border: 2px #eeeeee solid;
    color: #eeeeee;
    transform: scale(1.02);
  }
}

.projects-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100vw;
  // padding: 3vw;
  margin-bottom: 10vh;
  gap: 10rem;
  button {
    color: white;
    margin-top: 1.25rem;
    background-color: rgb(255, 64, 64);
    padding: 0.75rem;
    border-radius: 50rem;
  }
  .pdf-container {
    width: 90vw;
    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: white;
      button {
        color: white;
        margin-top: 1.25rem;
        background-color: rgb(255, 64, 64);
        padding: 0.75rem;
        border-radius: 50rem;
      }
    }
  }
  div {
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: white;
    // margin: 2rem;
  }
  .card {
    width: 65vh;
    aspect-ratio: 7/9;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white;
    border-radius: 35px;
    margin: 1.5rem;
    filter: drop-shadow(15px 15px 5px black);
    .card-inner-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0.25rem;
      width: calc(100% - 0.5rem);
      height: calc(100% - 0.5rem);
      background-color: black;
      border-radius: 35px;
      div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      img {
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
        aspect-ratio: 3/2;
        width: 100%;
      }
      h1 {
        font-size: 5.5vh;
        font-weight: normal;
        // font-family: noticia-bold;
        padding-left: 40px;
        margin-top: 10px;
        margin-bottom: 0;
      }
      p {
        color: white;
        padding-left: 66px;
        padding-right: 10px;
        margin-top: 11px;
        margin-bottom: 0;
        // font-family: noticia-regular;
        font-size: 2.5vh;
      }
      a {
        color: white;
        text-decoration: underline;
        margin-left: 3rem;
        margin-top: 0.5rem;
      }
    }
    @media only screen and (max-width: 600px) {
      width: 90vw;
    }
  }
}
