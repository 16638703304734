footer {
  display: flex;
  justify-content: space-between;
  padding: 1.5vw;
  font-size: calc(1vw + 0.25rem);
  color: white;

  img{
    width: 10vw;
    height: 10vw;
    margin: {
      top: 2vw;
      left: 2vw;
    }
  }

  width: 100vw;
}

footer {
  margin-top: auto;
}

.footer-info-container {
  display: flex;
  justify-content: space-between;
  margin: {
    left: 4vw;
  };
  width: 74vw;
}

.footer-section {
  border-left: white solid 3px;
  padding: {
    left: 3vw;
    right: 3vw;
    top: 1vw;
    bottom: 1vw;
  }
  width: 100%;
  text-align: center;

  ul {
    width: 100%;
  }

  li {
    margin: {
      top: 1.25vh;
      bottom: 1.25vh;
    }
  }
}

.footer-text {
  color: white;
  text-decoration: underline;

  &:visited{
    color: white;
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}