menu.li {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid red;
  margin-bottom: -5px;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

main,
article,
aside,
figure,
footer,
header,
nav,
section,
details,
summary {
  display: block;
}

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

img,
object,
embed {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
blockquote::before, blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted #222;
  cursor: help;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word;
}

input[type=radio] {
  vertical-align: text-bottom;
}

input[type=checkbox] {
  vertical-align: bottom;
}

.ie7 input[type=checkbox] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select,
input,
textarea {
  font-size: 99%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

button,
input[type=button] {
  width: auto;
  overflow: hidden;
}

.ie7 img {
  -ms-interpolation-mode: bicubic;
}

.clearfix::after {
  content: " ";
  display: block;
  clear: both;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: hsl(210, 30%, 52%);
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
    overflow-x: hidden;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
button {
  font-family: inherit;
}

body {
  background: hsl(200, 70%, 60%);
  color: hsl(200, 70%, 45%);
  line-height: 1.5;
  font-size: 0.875rem;
}

main {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-loading {
  min-height: calc(100vh - 120.8px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.container {
  width: 90vw;
  margin: 5rem 0;
  max-width: 620px;
  background: #eee;
  border-radius: 0.25rem;
  padding: 1.5rem 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.container h3 {
  font-weight: normal;
  text-transform: none;
  margin-bottom: 2rem;
}
.container button {
  color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 45%;
  border-color: transparent;
  background: hsl(200, 70%, 52%);
  margin: 2rem auto 0 auto;
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 0.5rem 0;
  letter-spacing: 0.1rem;
  border-radius: 0.25rem;
  outline: none;
}
.container .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}

@media only screen and (min-width: 1024px) {
  .section {
    width: 95vw;
  }
}
.list {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.75rem;
  margin-bottom: 1.5rem;
  border-radius: 3rem;
}
.list img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.list h4 {
  margin-bottom: 0.35rem;
}
.list p {
  margin-bottom: 0;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 10;
  top: 0;
  height: 110px;
  padding: 0.5rem;
  font-size: 1.25rem;
  width: 100vw;
}
nav img {
  width: 3.25rem;
  height: 3.25rem;
  margin-left: 1rem;
}
nav .nav-btn-section {
  height: 3.5rem;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}
nav .nav-btn-section .nav-btn {
  color: white;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  transform: scale(1);
  transition: 0.15s;
}
nav .nav-btn-section .nav-btn:hover {
  transform: scale(1.15);
}
nav .colorPicker {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: absolute;
  top: 15px;
  left: 5px;
  width: 450px;
  height: 300px;
  background-color: rgb(0, 0, 0);
  border: 1px solid #000000;
  border-radius: 8px;
}
nav .colorPicker .nav-minus {
  aspect-ratio: 1/1;
  filter: invert(1);
  margin-left: auto;
  margin-right: auto;
}
nav .colorPicker input {
  width: 100px;
  height: 50px;
  border-radius: 100px;
}
nav .colorPicker input[type=text] {
  border: 1px solid white;
  width: 80%;
  color: white;
  padding-left: 10px;
}
nav .colorPicker .color-button {
  background-color: white;
  color: black;
  width: 120px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 8px;
}
nav .colorPicker .bottomButton {
  margin-left: auto;
  margin-right: auto;
}

.burger-icon {
  display: none;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 40vw;
  top: 110px;
  transition: 0.35s ease-in-out;
  padding: 4vw;
  font-size: 6vw;
  font-weight: normal;
  transform: translateX(100%);
  width: 60vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7568627451);
}

@media screen and (max-width: 650px) {
  .nav-btn {
    display: none;
  }
  .mobile-nav-btn {
    color: white;
    font-weight: normal;
    padding-bottom: 4vw;
  }
  .burger-icon {
    display: block;
    margin-right: 1rem;
  }
  .mobile-nav.mobile-active {
    transform: translateX(0%);
  }
}
footer {
  display: flex;
  justify-content: space-between;
  padding: 1.5vw;
  font-size: calc(1vw + 0.25rem);
  color: white;
  width: 100vw;
}
footer img {
  width: 10vw;
  height: 10vw;
  margin-top: 2vw;
  margin-left: 2vw;
}

footer {
  margin-top: auto;
}

.footer-info-container {
  display: flex;
  justify-content: space-between;
  margin-left: 4vw;
  width: 74vw;
}

.footer-section {
  border-left: white solid 3px;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  width: 100%;
  text-align: center;
}
.footer-section ul {
  width: 100%;
}
.footer-section li {
  margin-top: 1.25vh;
  margin-bottom: 1.25vh;
}

.footer-text {
  color: white;
  text-decoration: underline;
}
.footer-text:visited {
  color: white;
}
.footer-text:hover {
  text-decoration: none;
  cursor: pointer;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #929292;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 11px;
}

*::-webkit-scrollbar-track {
  background: #eee;
}

*::-webkit-scrollbar-thumb {
  background-color: #929292;
  border-radius: 0px;
  border: 0px none #eee;
}

.admin-login-form-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-login-form-container .admin-login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1vw white solid;
  padding-top: 10vh;
  padding-bottom: 10vh;
  border-radius: 2vw;
}
.admin-login-form-container .admin-login-form h1 {
  font-size: 3vw;
}
.admin-login-form-container .admin-login-form button {
  border: 0.2vw white solid;
  width: 20vw;
  margin: 1vw;
  font-size: 1vw;
  color: white;
  transform: scale(1);
  transition: 0.5s;
  aspect-ratio: 16/2;
  border-radius: 6vw;
}
.admin-login-form-container .admin-login-form input {
  border: 0.2vw white solid;
  border-radius: 6vw;
  margin: 1vw;
  padding-left: 1.25vw;
  width: 30vw;
  aspect-ratio: 16/2;
  font-size: 1vw;
  color: white;
  transform: scale(1);
  transition: 0.5s;
}
.admin-login-form-container .admin-login-form input:focus {
  border: 0.2vw rgb(96, 215, 255) solid;
  transform: scale(1.01);
  outline: none;
}
.admin-login-form-container .qr-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1vw white solid;
  padding-top: 10vh;
  padding-bottom: 10vh;
  border-radius: 2vw;
}
.admin-login-form-container .qr-form img {
  border-radius: 25px;
}
.admin-login-form-container .qr-form input {
  border: 0.2vw white solid;
  border-radius: 6vw;
  margin: 1vw;
  padding-left: 1.25vw;
  width: 30vw;
  aspect-ratio: 16/2;
  font-size: 1vw;
  color: white;
  transform: scale(1);
  transition: 0.5s;
}
.admin-login-form-container .qr-form input:focus {
  border: 0.2vw rgb(96, 215, 255) solid;
  transform: scale(1.01);
  outline: none;
}
.admin-login-form-container .qr-form button {
  border: 0.2vw white solid;
  width: 20vw;
  margin: 1vw;
  font-size: 1vw;
  color: white;
  transform: scale(1);
  transition: 0.5s;
  aspect-ratio: 16/2;
  border-radius: 6vw;
}

.plus-button {
  height: 2rem;
  aspect-ratio: 1/1;
  position: absolute;
  filter: invert(1);
  margin-right: 7rem;
  margin-top: 2rem;
}

.minus-button {
  height: 2rem;
  aspect-ratio: 1/1;
  position: absolute;
  filter: invert(1);
  margin-top: 15rem;
}

.nav-plus {
  aspect-ratio: 1/1;
  filter: invert(1);
  position: absolute;
  top: 30px;
  left: 20px;
}

.color-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  transform: translate(-30rem, -10rem);
  height: 100px;
}
.color-picker .rgba-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.color-picker .rgba-picker .rgba-fields {
  width: auto;
  height: 100px;
  justify-content: space-evenly;
}
.color-picker .rgba-picker .rgba-fields div {
  display: flex;
}

.vertical-color {
  flex-direction: column;
  transform: translate(-10rem, -5rem);
}

textarea {
  font-size: inherit;
  font-family: inherit;
  resize: none;
  height: auto;
}

.project-form {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  border: 1px solid white;
  padding: 2rem;
  border-radius: 2rem;
}
.project-form input {
  color: white;
}
.project-form div input {
  width: 100%;
}
.project-form div p {
  margin-bottom: 0;
}
.project-form #date {
  color-scheme: dark;
}
.project-form button {
  background-color: rgb(0, 121, 0);
  width: 10rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 500px;
  margin: 0 auto;
  color: white;
}

.admin-project-container {
  display: flex;
  flex-direction: column;
}

.gradient-container .color-picker {
  transform: translate(2rem, -5rem);
  z-index: 1000;
}

.landing-section {
  height: calc(100vh - 15rem);
  padding-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-image: url(../../Images/journey.png);
  background-size: 100%;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  transform: rotate(0deg);
}
.landing-section h1 {
  font-size: clamp(1.5rem, 0.75rem + 4vw, 3rem);
  color: white;
  letter-spacing: 0.25vw;
  font-weight: normal;
  letter-spacing: 0.25vw;
  font-weight: normal;
}
.landing-section .journey-container-1 {
  top: 33rem;
  left: 10vw;
}
@media only screen and (max-height: 950px) {
  .landing-section .journey-container-1 {
    top: 32rem;
  }
}
@media only screen and (max-height: 850px) {
  .landing-section .journey-container-1 {
    top: 30rem;
  }
}
@media only screen and (max-height: 750px) {
  .landing-section .journey-container-1 {
    top: 29rem;
  }
}
@media only screen and (max-height: 650px) {
  .landing-section .journey-container-1 {
    top: 28rem;
  }
}
@media only screen and (max-height: 550px) {
  .landing-section .journey-container-1 {
    top: 27rem;
  }
}
.landing-section .journey-container-2 {
  margin-right: 5vw;
  margin-top: 2vh;
}
.landing-section .journey-container-3 {
  top: 30rem;
  right: 13vw;
}
@media only screen and (max-height: 950px) {
  .landing-section .journey-container-3 {
    top: 29rem;
  }
}
@media only screen and (max-height: 850px) {
  .landing-section .journey-container-3 {
    top: 28rem;
  }
}
@media only screen and (max-height: 750px) {
  .landing-section .journey-container-3 {
    top: 27rem;
  }
}
@media only screen and (max-height: 650px) {
  .landing-section .journey-container-3 {
    top: 26rem;
  }
}
@media only screen and (max-height: 550px) {
  .landing-section .journey-container-3 {
    top: 25rem;
  }
}

.home-section {
  height: 60vw;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
}
.home-section .tall-home-item {
  width: 20vw;
  height: 40vw;
  background-color: black;
  border-radius: 2vw;
  padding-top: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 1vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.home-section .tall-home-item h1 {
  font-size: 2vw;
  font-weight: normal;
  text-align: center;
  width: 100%;
}
.home-section .tall-home-item div h1 {
  font-size: 2.25vw;
}
.home-section .tall-home-item div h3 {
  font-size: 2vw;
}
.home-section .tall-home-item div p {
  color: white;
  font-size: 1.5vw;
  margin-bottom: 0;
}
.home-section .tall-home-item h6 {
  font-size: 1.25vw;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.25vw;
}
.home-section #first-tall-home-item {
  animation: animatedBackground 1s ease-in-out 1;
  animation-fill-mode: forwards;
}
.home-section .multi-home-item {
  width: 55vw;
  height: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 7vh;
}
.home-section .multi-home-item .long-home-item {
  animation: animatedBackground 2s ease-in-out 1;
  animation-fill-mode: forwards;
  height: 20vw;
  width: 100%;
  background-color: black;
  border-radius: 2vw;
  padding: 2vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: space-between;
}
.home-section .multi-home-item .long-home-item .top-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-section .multi-home-item .long-home-item .top-content .left-content {
  display: flex;
  flex-direction: column;
}
.home-section .multi-home-item .long-home-item .top-content .bottom-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-section .multi-home-item .long-home-item .top-content .bottom-content .caption {
  color: white;
  font-size: 1.5vw;
  font-size: clamp(0.5rem, 0.313rem + 1vw, 1rem);
}
.home-section .multi-home-item .long-home-item h1 {
  font-size: 2vw;
}
.home-section .multi-home-item .long-home-item p {
  color: white;
  font-size: 1.5vw;
  width: 50vw;
  margin-left: 1vw;
  margin-bottom: 0;
}
.home-section .multi-home-item .long-home-item button {
  color: white;
  font-size: 1vw;
  border-radius: 5vw;
  text-align: center;
  padding-top: 1vw;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 1vw;
}
.home-section .multi-home-item .about-home-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.home-section .multi-home-item .about-home-item h1 {
  margin-bottom: 0;
}
.home-section .multi-home-item .about-home-item .text-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-section .multi-home-item .about-home-item .text-container p {
  font-size: 1vw;
  padding: 0;
  margin: 0;
  width: 90%;
}
.home-section .multi-home-item .about-home-item .text-spacer {
  color: rgba(0, 0, 0, 0);
}
.home-section .multi-home-item .useful-home-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home-section .multi-home-item .useful-home-item .useful-title {
  width: 100%;
  text-align: left;
  flex-grow: 0;
  margin: 0;
  position: relative;
}
.home-section .multi-home-item .useful-home-item .button-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.home-section .multi-home-item .useful-home-item .button-container button {
  background-color: white;
  color: black;
  border-radius: 1vw;
}
.home-section .second-multi-home-item {
  align-items: flex-end;
}

.home-img {
  height: calc(100vh - 3.75rem);
  width: 100vw;
}

@media screen and (max-width: 750px) {
  .home-section {
    height: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-section .tall-home-item {
    display: none;
    padding: 0;
    margin: 0;
    height: 0;
    width: 0;
  }
  .home-section .multi-home-item {
    width: 100vw;
    height: 80vw;
  }
  .home-section .multi-home-item .long-home-item {
    width: 80vw;
    height: 35vw;
    padding: 3vw;
    border-radius: 4vw;
  }
  .home-section .multi-home-item .long-home-item p {
    font-size: 2.25vw;
    width: 70vw;
    height: 12vw;
  }
  .home-section .multi-home-item .long-home-item h1 {
    font-size: 3vw;
  }
  .home-section .multi-home-item .long-home-item button {
    width: 28vw;
    height: 8vw;
    font-size: 2.25vw;
  }
  .home-section .multi-home-item .about-home-item .text-container p {
    font-size: clamp(0.34rem, 0.033rem + 1.636vw, 0.8rem);
    height: auto;
  }
  .home-section .multi-home-item .useful-home-item .button-container button {
    width: 20vw;
    border-radius: 3vw;
  }
  .home-section .second-multi-home-item {
    align-items: center;
  }
}
@keyframes animatedBackground {
  from {
    background-position: 35% 35%;
  }
  to {
    background-position: center center;
  }
}
.candedly {
  height: 90vh;
  max-height: 650px;
  margin-top: auto;
  margin-bottom: auto;
}

@media only screen and (max-width: 648px) {
  .candedly {
    height: 90vh;
    padding-top: 15vh;
  }
}
.project-container-main {
  width: 100vw;
  padding: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.project-container-main h1 {
  font-size: 4.5vw;
  color: #eeeeee;
}

.project-search {
  border: 2px #7c7c7c solid;
  border-radius: 2vw;
  outline: none;
  color: #7c7c7c;
  padding: 1vw;
  margin-top: 1.5vw;
  width: 50vw;
  font-size: 1.75vw;
  transform: scale(1);
  transition: 0.2s;
}
.project-search:focus {
  border: 2px #eeeeee solid;
  color: #eeeeee;
  transform: scale(1.02);
}

.projects-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100vw;
  margin-bottom: 10vh;
  gap: 10rem;
}
.projects-container button {
  color: white;
  margin-top: 1.25rem;
  background-color: rgb(255, 64, 64);
  padding: 0.75rem;
  border-radius: 50rem;
}
.projects-container .pdf-container {
  width: 90vw;
}
.projects-container .pdf-container div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}
.projects-container .pdf-container div button {
  color: white;
  margin-top: 1.25rem;
  background-color: rgb(255, 64, 64);
  padding: 0.75rem;
  border-radius: 50rem;
}
.projects-container div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: white;
}
.projects-container .card {
  width: 65vh;
  aspect-ratio: 7/9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  border-radius: 35px;
  margin: 1.5rem;
  filter: drop-shadow(15px 15px 5px black);
}
.projects-container .card .card-inner-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0.25rem;
  width: calc(100% - 0.5rem);
  height: calc(100% - 0.5rem);
  background-color: black;
  border-radius: 35px;
}
.projects-container .card .card-inner-content div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.projects-container .card .card-inner-content img {
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  aspect-ratio: 3/2;
  width: 100%;
}
.projects-container .card .card-inner-content h1 {
  font-size: 5.5vh;
  font-weight: normal;
  padding-left: 40px;
  margin-top: 10px;
  margin-bottom: 0;
}
.projects-container .card .card-inner-content p {
  color: white;
  padding-left: 66px;
  padding-right: 10px;
  margin-top: 11px;
  margin-bottom: 0;
  font-size: 2.5vh;
}
.projects-container .card .card-inner-content a {
  color: white;
  text-decoration: underline;
  margin-left: 3rem;
  margin-top: 0.5rem;
}
@media only screen and (max-width: 600px) {
  .projects-container .card {
    width: 90vw;
  }
}

#root {
  overflow-x: hidden;
}

.timeline-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 250px;
  overflow-x: hidden;
  width: 100vw;
}
@media (max-width: 1000px) {
  .timeline-section {
    margin-top: 3rem;
    min-height: 100vh;
  }
}
@media (min-width: 1000px) {
  .timeline-section {
    height: 67vh;
  }
}
.timeline-section .timeline {
  height: 50vh;
  transition: 0.65s ease-in-out;
  background-position: top left;
  background-size: 200vw 50%;
  background-repeat: repeat-x;
  position: absolute;
  display: flex;
}
@media (max-width: 1000px) {
  .timeline-section .timeline {
    display: none;
  }
}
.timeline-section .timeline .timeline-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: fade-in 0.7s ease-in-out;
  filter: none;
  gap: 0.25rem;
}
.timeline-section .timeline .timeline-item .outer-circle {
  width: 1.25vw;
  height: 1.25vw;
  border-radius: 50%;
  border: 0.1vw solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-section .timeline .timeline-item .outer-circle .inner-circle {
  background-color: white;
  height: 0.7vw;
  width: 0.7vw;
  border-radius: 50%;
}
.timeline-section .timeline .timeline-item h3 {
  font-size: 34px;
  font-weight: normal;
  color: white;
}
.timeline-section .timeline .timeline-item .project-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
  min-width: 18vw;
  aspect-ratio: 3/1;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 20rem;
}
.timeline-section .timeline .timeline-item .project-card h2 {
  color: white;
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 0;
}
.timeline-section .timeline .timeline-item .project-card button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-section .timeline #timeline-1 {
  transform: translateY(-3rem);
}
.timeline-section .timeline #timeline-2 {
  flex-direction: column-reverse;
  transform: translateY(-14rem);
}
.timeline-section .timeline #timeline-3 {
  transform: translateY(-1rem);
}
.timeline-section .timeline #timeline-4 {
  transform: translateY(6rem);
}
.timeline-section .timeline #timeline-5 {
  transform: translateY(3rem);
}
.timeline-section .timeline #timeline-6 {
  flex-direction: column-reverse;
  transform: translateY(-9rem);
}
.timeline-section .mobile-large-timeline {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: clamp(0rem, -0.521rem + 1.667vw, 0.313rem);
  overflow-y: visible;
  margin-bottom: 3rem;
}
@media only screen and (min-width: 1000px) {
  .timeline-section .mobile-large-timeline {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .timeline-section .mobile-large-timeline {
    display: none;
  }
}
.timeline-section .mobile-large-timeline .line {
  position: absolute;
  width: 5px;
  min-height: 100vh;
  height: calc(100% + 10vh);
  background-color: #ff3c6b;
  z-index: -1;
  box-shadow: 0px 0px 50px 4px #ff3c6b;
}
@media only screen and (max-height: 900px) {
  .timeline-section .mobile-large-timeline .line {
    height: calc(100% + 10rem);
  }
}
@media only screen and (max-height: 800px) {
  .timeline-section .mobile-large-timeline .line {
    height: calc(100% + 20rem);
  }
}
@media only screen and (max-height: 700px) {
  .timeline-section .mobile-large-timeline .line {
    height: calc(100% + 30rem);
  }
}
@media only screen and (max-height: 600px) {
  .timeline-section .mobile-large-timeline .line {
    height: calc(100% + 40rem);
  }
}
.timeline-section .mobile-large-timeline .timeline-item {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.timeline-section .mobile-large-timeline .timeline-item .dashed-line {
  height: 5rem;
}
.timeline-section .mobile-large-timeline .timeline-item .date-project-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 35%;
}
.timeline-section .mobile-large-timeline .timeline-item .date-project-container h3 {
  color: white;
  margin-bottom: 0;
  font-size: 1.75rem;
}
.timeline-section .mobile-large-timeline .timeline-item .date-project-container .project-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: clamp(11rem, 1rem + 32vw, 16rem);
  aspect-ratio: 5/1;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 220rem;
}
.timeline-section .mobile-large-timeline .timeline-item .date-project-container .project-card h2 {
  color: white;
  font-weight: normal;
  font-size: clamp(0.938rem, 0.313rem + 2vw, 1.25rem);
  margin-bottom: 0;
}
.timeline-section .mobile-large-timeline .timeline-item .date-project-container .project-card button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-section .mobile-large-timeline #timeline-0 {
  transform: translateX(calc(50% + 2.5rem));
}
.timeline-section .mobile-large-timeline #timeline-0 .dashed-line {
  transform: rotate(90deg) translateY(40%);
}
.timeline-section .mobile-large-timeline #timeline-0 .date-project-container {
  align-items: flex-start;
  margin-left: 1rem;
}
.timeline-section .mobile-large-timeline #timeline-1 {
  transform: translateX(calc(-50% - 2.5rem));
  flex-direction: row-reverse;
}
.timeline-section .mobile-large-timeline #timeline-1 .dashed-line {
  transform: rotate(90deg) translateY(-40%);
}
.timeline-section .mobile-large-timeline #timeline-1 .date-project-container {
  align-items: flex-end;
  margin-right: 1rem;
}
.timeline-section .mobile-small-timeline {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: clamp(0rem, -0.521rem + 1.667vw, 0.313rem);
  overflow-y: visible;
  margin-bottom: 3rem;
}
@media only screen and (min-width: 500px) {
  .timeline-section .mobile-small-timeline {
    display: none;
  }
}
.timeline-section .mobile-small-timeline .line {
  position: fixed;
  width: 5px;
  min-height: 100vh;
  height: 100vh;
  top: 0;
  margin-left: clamp(2rem, -2rem + 21.333vw, 4rem);
  background-color: #ff3c6b;
  z-index: -1;
  box-shadow: 0px 0px 50px 4px #ff3c6b;
}
@media only screen and (max-height: 900px) {
  .timeline-section .mobile-small-timeline .line {
    height: calc(100% + 10rem);
  }
}
@media only screen and (max-height: 800px) {
  .timeline-section .mobile-small-timeline .line {
    height: calc(100% + 20rem);
  }
}
@media only screen and (max-height: 700px) {
  .timeline-section .mobile-small-timeline .line {
    height: calc(100% + 30rem);
  }
}
@media only screen and (max-height: 600px) {
  .timeline-section .mobile-small-timeline .line {
    height: calc(100% + 40rem);
  }
}
.timeline-section .mobile-small-timeline .timeline-item {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transform: translateX(clamp(4rem, -2rem + 32vw, 7rem));
}
.timeline-section .mobile-small-timeline .timeline-item .dashed-line {
  height: 4rem;
  transform: rotate(90deg) translateY(40%);
}
.timeline-section .mobile-small-timeline .timeline-item .date-project-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 35%;
  align-items: flex-start;
  margin-left: 1rem;
}
.timeline-section .mobile-small-timeline .timeline-item .date-project-container h3 {
  color: white;
  margin-bottom: 0;
  font-size: clamp(1.5rem, 1rem + 2.667vw, 1.75rem);
}
.timeline-section .mobile-small-timeline .timeline-item .date-project-container .project-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: clamp(11rem, -1rem + 64vw, 16rem);
  aspect-ratio: 4/1;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 220rem;
}
.timeline-section .mobile-small-timeline .timeline-item .date-project-container .project-card h2 {
  color: white;
  font-weight: normal;
  font-size: clamp(0.938rem, 0.313rem + 2vw, 1.25rem);
  margin-bottom: 0;
}
.timeline-section .mobile-small-timeline .timeline-item .date-project-container .project-card button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-section .button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 90vh;
  width: 20vw;
  left: 40vw;
  position: absolute;
  gap: 1rem;
}
@media (max-width: 1000px) {
  .timeline-section .button-container {
    display: none;
  }
}
.timeline-section .button-container button {
  color: white;
  filter: invert(1);
  height: 3rem;
}
.timeline-section .button-container button img {
  height: 100%;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@font-face {
  font-family: noticia-regular;
  src: url(../Fonts/Noticia_Text/NoticiaText-Regular.ttf);
}
@font-face {
  font-family: noticia-bold;
  src: url(../Fonts/Noticia_Text/NoticiaText-Bold.ttf);
}
@font-face {
  font-family: noticia-italic;
  src: url(../Fonts/Noticia_Text/NoticiaText-Italic.ttf);
}
@font-face {
  font-family: noticia-italic-bold;
  src: url(../Fonts/Noticia_Text/NoticiaText-BoldItalic.ttf);
}
img {
  overflow: hidden;
}

body {
  background-color: #26262b;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}

nav,
footer {
  background-color: #5a023f;
}

nav {
  border-bottom: 1.8rem #d37e9f solid;
}

footer {
  border-top: 0.5rem #d37e9f solid;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}