@use '../themes/defaultTheme' as *;
@use '../util/variables'as *;
@use '../util/mixins'as *;

.list {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.75rem;
  margin-bottom: 1.5rem;
  border-radius: 3rem;
  img {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: $light-shadow;
  }
  h4 {
    margin-bottom: 0.35rem;
  }
  p {
    margin-bottom: 0;
  }
}
