.candedly {
 height: 90vh;
 max-height: 650px;
 margin-top: auto;
 margin-bottom: auto;
}

@media only screen and (max-width: 648px) {
 .candedly {
  height: 90vh;
  padding-top: 15vh;
 }
}
