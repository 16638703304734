.landing-section {
  height: calc(100vh - 15rem);
  padding-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-image: url(../../Images/journey.png);
  background-size: 100%;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  transform: rotate(0deg);
  // @media only screen and (max-height: 950px) {
  //   background-image: linear-gradient(90deg, #26262b00 40%, #26262b 80%),
  //     url(../../Images/journey.png);

  //   transform: rotate(90deg);
  //   // width: calc(100vh - 15rem);
  //   height: 80vh;
  //   padding-top: 0;
  //   flex-direction: row;
  //   h1 {
  //     rotate: (-90deg);
  //   }
  // }
  h1 {
    font-size: clamp(1.5rem, 0.75rem + 4vw, 3rem);
    color: white;
    // font-family: noticia-italic-bold;
    letter-spacing: 0.25vw;
    font-weight: normal;
    letter-spacing: 0.25vw;
    font-weight: normal;
  }
  .journey-container-1 {
    top: 33rem;
    @media only screen and (max-height: 950px) {
      top: 32rem;
    }
    @media only screen and (max-height: 850px) {
      top: 30rem;
    }
    @media only screen and (max-height: 750px) {
      top: 29rem;
    }
    @media only screen and (max-height: 650px) {
      top: 28rem;
    }
    @media only screen and (max-height: 550px) {
      top: 27rem;
    }
    left: 10vw;
  }
  .journey-container-2 {
    margin-right: 5vw;
    margin-top: 2vh;
  }
  .journey-container-3 {
    top: 30rem;
    @media only screen and (max-height: 950px) {
      top: 29rem;
    }
    @media only screen and (max-height: 850px) {
      top: 28rem;
    }
    @media only screen and (max-height: 750px) {
      top: 27rem;
    }
    @media only screen and (max-height: 650px) {
      top: 26rem;
    }
    @media only screen and (max-height: 550px) {
      top: 25rem;
    }
    right: 13vw;
  }
}

.home-section {
  height: 60vw;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  .tall-home-item {
    width: 20vw;
    height: 40vw;
    background-color: black;
    border-radius: 2vw;
    padding: {
      top: 2vw;
      left: 2vw;
      right: 2vw;
      bottom: 1vw;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    h1 {
      font-size: 2vw;
      // font-family: noticia-bold;
      font-weight: normal;
      text-align: center;
      width: 100%;
    }
    div {
      h1 {
        font-size: 2.25vw;
        // font-family: noticia-bold;
      }
      h3 {
        font-size: 2vw;
        // font-family: noticia-regular;
      }
      p {
        color: white;
        font-size: 1.5vw;
        // font-family: noticia-regular;
        margin-bottom: 0;
      }
    }
    h6 {
      font-size: 1.25vw;
      // font-family: noticia-bold;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.25vw;
    }
  }
  #first-tall-home-item {
    animation: animatedBackground 1s ease-in-out 1;
    animation-fill-mode: forwards;
  }
  .multi-home-item {
    width: 55vw;
    height: 40vw;
    // margin: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 7vh;
    .long-home-item {
      animation: animatedBackground 2s ease-in-out 1;
      animation-fill-mode: forwards;
      height: 20vw;
      width: 100%;
      background-color: black;
      border-radius: 2vw;
      padding: 2vw;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: space-between;
      .top-content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .left-content {
          display: flex;
          flex-direction: column;
        }
        .bottom-content {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .caption {
            color: white;
            font-size: 1.5vw;
            // font-family: noticia-regular;
            // width: 50vw;
            // height: 6vw;
            font-size: clamp(0.5rem, 0.313rem + 1vw, 1rem);
          }
        }
      }
      h1 {
        font-size: 2vw;
        // font-family: noticia-bold;
      }
      p {
        color: white;
        font-size: 1.5vw;
        // font-family: noticia-regular;
        width: 50vw;
        margin-left: 1vw;
        margin-bottom: 0;
      }
      button {
        color: white;
        font-size: 1vw;
        // font-family: noticia-bold;
        border-radius: 5vw;
        text-align: center;
        padding: {
          top: 1vw;
          left: 2vw;
          right: 2vw;
          bottom: 1vw;
        }
      }
    }
    .about-home-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      h1 {
        margin-bottom: 0;
      }
      .text-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 1vw;
          padding: 0;
          margin: 0;
          width: 90%;
        }
      }
      .text-spacer {
        color: #00000000;
      }
    }
    .useful-home-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .useful-title {
        width: 100%;
        text-align: left;
        flex-grow: 0;
        margin: 0;
        position: relative;
      }
      .button-container {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        button {
          background-color: white;
          color: black;
          border-radius: 1vw;
        }
      }
    }
  }
  .second-multi-home-item {
    align-items: flex-end;
  }
}

.home-img {
  height: calc(100vh - 3.75rem);
  width: 100vw;
}

@media screen and (max-width: 750px) {
  .home-section {
    height: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .tall-home-item {
      display: none;
      padding: 0;
      margin: 0;
      height: 0;
      width: 0;
    }

    .multi-home-item {
      width: 100vw;
      height: 80vw;
      .long-home-item {
        width: 80vw;
        height: 35vw;
        padding: 3vw;
        border-radius: 4vw;

        p {
          font-size: 2.25vw;
          width: 70vw;
          height: 12vw;
        }
      }

      .long-home-item {
        h1 {
          font-size: 3vw;
        }

        button {
          width: 28vw;
          height: 8vw;

          font-size: 2.25vw;
        }
      }

      .about-home-item .text-container p {
        font-size: clamp(0.34rem, 0.033rem + 1.636vw, 0.8rem);
        height: auto;
      }

      .useful-home-item .button-container button {
        width: 20vw;
        border-radius: 3vw;
      }
    }

    .second-multi-home-item {
      align-items: center;
    }
  }
}
@keyframes animatedBackground {
  from {
    background-position: 35% 35%;
  }
  to {
    background-position: center center;
  }
}
