// Fonts for Noticia
@font-face {
  font-family: noticia-regular;
  src: url(../Fonts/Noticia_Text/NoticiaText-Regular.ttf);
}
@font-face {
  font-family: noticia-bold;
  src: url(../Fonts/Noticia_Text/NoticiaText-Bold.ttf);
}
@font-face {
  font-family: noticia-italic;
  src: url(../Fonts/Noticia_Text/NoticiaText-Italic.ttf);
}
@font-face {
  font-family: noticia-italic-bold;
  src: url(../Fonts/Noticia_Text/NoticiaText-BoldItalic.ttf);
}
