nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 10;
  top: 0;
  height: 110px;
  padding: 0.5rem;
  font-size: 1.25rem;
  width: 100vw;
  img {
    width: 3.25rem;
    height: 3.25rem;
    margin-left: 1rem;
  }
  .nav-btn-section {
    height: 3.5rem;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    // font-family: noticia-regular;
    .nav-btn {
      color: white;
      margin: {
        left: 1rem;
        right: 1rem;
      }
      // font-family: noticia-regular;
      cursor: pointer;
      transform: scale(1);
      transition: 0.15s;

      &:hover {
        transform: scale(1.15);
      }
    }
  }
  .colorPicker {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    position: absolute;
    top: 15px;
    left: 5px;
    width: 450px;
    height: 300px;
    background-color: rgb(0, 0, 0);
    border: 1px solid #000000;
    border-radius: 8px;
    .nav-minus {
      aspect-ratio: 1/1;
      filter: invert(1);
      margin-left: auto;
      margin-right: auto;
    }
    input {
      width: 100px;
      height: 50px;
      border-radius: 100px;
    }
    input[type='text'] {
      border: 1px solid white;
      width: 80%;
      color: white;
      padding-left: 10px;
    }
    .color-button {
      background-color: white;
      color: black;
      width: 120px;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 8px;
    }
    .bottomButton {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.burger-icon {
  display: none;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 40vw;
  top: calc(110px);
  transition: 0.35s ease-in-out;
  padding: 4vw;
  font-size: 6vw;
  font-weight: normal;
  transform: translateX(100%);
  width: 60vw;
  height: 100vh;
  background-color: #000000c1;
}

@media screen and (max-width: 650px) {
  .nav-btn {
    display: none;
  }

  .mobile-nav-btn {
    color: white;
    // font-family: noticia-regular;
    font-weight: normal;
    padding-bottom: 4vw;
  }

  .burger-icon {
    display: block;
    margin-right: 1rem;
  }

  .mobile-nav.mobile-active {
    transform: translateX(0%);
  }
}
